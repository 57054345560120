import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import loadable from '@loadable/component';

import '../styles/blog-post.scss';

const Content = loadable(() => import('../components/content'));

const BlogPost = ({ data }) => {
  const post = data.markdownRemark;
  const metaData = {
    title: post.frontmatter.title
  };

  return (
    <Layout metaData={metaData}>
      <Content>
        <h1>{post.frontmatter.title}</h1>
      </Content>

      <article id="post">
        {post.frontmatter.featuredImage ? (
          <Img
            fluid={post.frontmatter.featuredImage.childImageSharp.fluid}
            title={post.frontmatter.title}
            alt={post.frontmatter.title}
            loading="eager"
            fadeIn={false}
          />
        ) : null}

        <Content>
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
        </Content>
      </article>
    </Layout>
  );
};

export default BlogPost;

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        excerpt
        date
        featuredImage {
          publicURL
          ...mediumImage
        }
      }
      fields {
        slug
      }
    }
  }
`;
